import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { CustomFilterBean } from 'api/base/api'
import { customFilterApi } from 'api/customFilters'

import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import { CustomButton, CustomRow, PageTitle } from 'components/common'
import { getListColumnWidth, ListTable, useListFilters } from 'components/list'

export const CustomFilterList: React.FC = () => {
  const history = useHistory()

  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => customFilterApi.list(filters), filters)

  const { nameWidth, columnWidth } = getListColumnWidth(5)

  const columns: ColumnsType<CustomFilterBean> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: 50,
      ellipsis: true,
      render: (_, { id }) => id,
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { name }) => name,
    },
    {
      title: 'Field ID',
      width: columnWidth,
      dataIndex: 'fieldId',
      render: (_, { fieldId }) => fieldId,
    },
    {
      title: 'Adapter Config Field',
      width: nameWidth,
      ellipsis: true,
      dataIndex: 'adapterConfigField',
      render: (_, { adapterConfigField }) => adapterConfigField,
    },
    {
      title: 'Dashboard Filter',
      width: columnWidth,
      dataIndex: 'dashboardFilter',
      render: (_, { dashboardFilter }) => (dashboardFilter ? 'Yes' : 'No'),
    },
    {
      width: 190,
      ellipsis: true,
      render: (_, { id }) => (
        <CustomButton
          type="shadow"
          size="small"
          icon={<EditOutlined />}
          onClick={() => history.push(routes.customFilter.edit(id))}
        >
          Edit
        </CustomButton>
      ),
    },
  ]

  return (
    <>
      <CustomRow justify="space-between" align="middle" marginBottom={34}>
        <PageTitle>Total custom filters number: {totalEntities}</PageTitle>

        <Col>
          <CustomButton type="primary" linkTo={routes.customFilter.create} icon={<PlusOutlined />}>
            Add Custom Filter
          </CustomButton>
        </Col>
      </CustomRow>

      <Row>
        <Col>
          <ListTable
            columns={columns}
            list={list}
            isLoading={isLoading}
            currentPage={filters.page}
            totalEntities={totalEntities}
            onPageChange={(page) => setFilter('page', page)}
            onSort={({ order, column }) =>
              setFilter(
                'sort',
                order && column?.dataIndex
                  ? {
                      name: column.dataIndex as string,
                      direction: order === 'ascend' ? 'asc' : 'desc',
                    }
                  : undefined,
              )
            }
          />
        </Col>
      </Row>
    </>
  )
}
