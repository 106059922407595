import { CameraOutlined } from '@ant-design/icons'
import { Col, Divider, Spin, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { RcFile } from 'antd/es/upload'
import React, { useState } from 'react'

import { settingsApi } from 'api/settings'

import { createMessage } from 'utils/createMessage'
import { userUpdateStore } from 'utils/userUpdateStore'

import { CustomButton, CustomRow, Explanation, Subtitle } from 'components/common'

import styles from 'pages/settings/logo/settingsLogo.module.scss'

export const SettingsLogo: React.FC = () => {
  const [isUploading, setIsUploading] = useState(false)

  function upload(file: RcFile) {
    if (file.size / 1024 / 1024 > 1) {
      createMessage('error', 'Image must smaller than 1MB!')
      return false
    }

    setIsUploading(true)
    settingsApi
      .uploadLogo(file)
      .then(() => {
        createMessage('success', 'Logo was successfully uploaded!')
        userUpdateStore.updateInfo()
      })
      .catch(() => createMessage('error', 'Logo was not uploaded!'))
      .finally(() => setIsUploading(false))

    return false
  }

  return (
    <>
      <CustomRow marginBottom={4}>
        <Col>
          <Subtitle level="h2">Add logo</Subtitle>
        </Col>
      </CustomRow>

      <CustomRow marginBottom={12}>
        <Col>
          <Explanation size={16}>
            Upload a logo to brand your agency interface. We recommend using at lease a 300x200px (2:1 ratio), no larger
            than 1MB
          </Explanation>
        </Col>
      </CustomRow>

      <CustomRow marginBottom={34}>
        <Col>
          <ImgCrop
            quality={1}
            fillColor="transparent"
            aspect={4}
            modalTitle="Add logo"
            modalClassName={styles.cropModal}
            modalOk="Save"
          >
            <Upload beforeUpload={upload} accept="image/png" showUploadList={false} disabled={isUploading}>
              <CustomButton type={isUploading ? 'disabled' : 'primary'} icon={<CameraOutlined />}>
                Upload file
                {isUploading && <Spin className={styles.loader} />}
              </CustomButton>
            </Upload>
          </ImgCrop>
        </Col>
      </CustomRow>

      <Divider className={styles.divider} />
    </>
  )
}
