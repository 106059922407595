class LoginStore {
  accessToken: null | string = null

  constructor() {
    this.rehydrateInfo()

    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')
    if (token) {
      this.login(token)
    }
  }

  login = (accessToken: null | string) => {
    this.accessToken = accessToken
    this.storeInfo()
  }

  logout = () => {
    this.accessToken = null
    this.storeInfo()
  }

  storeInfo = () => {
    localStorage.setItem('abAccessToken', JSON.stringify(this.accessToken))
  }

  rehydrateInfo = () => {
    this.accessToken = JSON.parse(localStorage.getItem('abAccessToken') as string)
  }
}

export const loginStore = new LoginStore()
