export const defaultColorPalette = {
  '--color-primary-1': '#334d97',
  '--color-primary-2': '#5888cf',
  '--color-primary-3': '#d6e6ff',
  '--color-secondary-1': '#ffa914',
  '--color-secondary-2': '#fecd6e',
  '--color-secondary-3': '#ffefcf',
  '--color-grey-1': '#6f757e',
  '--color-grey-2': '#9fa3a9',
  '--color-grey-3': '#cfd1d4',
  '--color-grey-4': '#f6f6f6',
  '--color-system-1': '#0d1a43',
  '--color-system-2': '#0f1928',
  '--color-system-3': '#f6521e',
  '--color-system-4': '#fff',
  '--color-system-5': '#3ebb61',
}

export type ColorPalette = typeof defaultColorPalette

const root = document.querySelector(':root') as HTMLElement

class ColorPaletteManager {
  get currentPalette() {
    return Object.keys(defaultColorPalette).reduce(
      (acc, key) => ({ ...acc, [key]: root.style.getPropertyValue(key) }),
      {} as ColorPalette,
    )
  }

  update = (colorPalette: ColorPalette, htmlElement: HTMLElement = root) => {
    Object.entries(colorPalette).forEach(([key, value]) => htmlElement.style.setProperty(key, value))
  }

  reset = (htmlElement: HTMLElement = root) => {
    Object.entries(defaultColorPalette).forEach(([key, value]) => htmlElement.style.setProperty(key, value))
  }
}

export const colorPaletteManager = new ColorPaletteManager()
