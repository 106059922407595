/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgencyCustomFilterControllerApi, AgencyCustomizationControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { ColorPalette } from 'utils/colorPalette'
import { loginStore } from 'utils/loginStore.ts'

class SettingsApi {
  customizationApi = new AgencyCustomizationControllerApi(configuration, configuration.basePath, customFetch)

  customFilterApi = new AgencyCustomFilterControllerApi(configuration, configuration.basePath, customFetch)

  uploadPalette = (colorPalette: ColorPalette) => this.customizationApi.uploadCustomSettings(colorPalette)

  uploadLogo = (logo: Blob) => {
    const formData = new FormData()
    formData.append('logo', logo)

    return fetch(`${import.meta.env.VITE_APPLICATION_ENDPOINT}/api/agency/custom/logo`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers: { 'X-Access-Token': loginStore.accessToken || '' },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve()
      }
      return Promise.reject()
    })
  }

  getCustomFilters = () => this.customFilterApi.getAllCustomFiltersByAgency()
}

export const settingsApi = new SettingsApi()
