import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AccountBean } from 'api/base/api'
import { publisherApi } from 'api/publisher'

import { useFormState } from 'components/form'

import StatusEnum = AccountBean.StatusEnum
import AccessRightsEnum = AccountBean.AccessRightsEnum

export function usePublisherFormData() {
  const { id } = useParams<{ id: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<AccountBean>()

  useEffect(() => {
    if (id) {
      publisherApi.entity(+id).then((bean) => {
        setFormName(bean.data.firstName ?? '')
        setFormState(bean.data)
      })
    } else {
      setFormState({
        agencyPageUrlEnabled: true,
        pageUrlEnabled: false,
        status: StatusEnum.ACTIVE,
        accessRights: AccessRightsEnum.FULL,
      })
    }
  }, [])

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
  }
}
