import { Color } from 'antd/es/color-picker'

interface Shades {
  color: string
  firstShade: string
  secondShade: string
}

export function createColorShades(color: Color): Shades {
  const { r, g, b } = color.toRgb()

  const rRest = 255 - r
  const gRest = 255 - g
  const bRest = 255 - b

  const firstFactor = 0.5
  const secondFactor = 0.8

  function componentToHex(c:number) {
    const hex = Math.trunc(c).toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }

  function rgbToHex(r:number, g:number, b:number) {
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
  }

  return {
    color: color.toHexString(),
    firstShade: rgbToHex(r + rRest * firstFactor, g + gRest * firstFactor, b + bRest * firstFactor),
    secondShade: rgbToHex(r + rRest * secondFactor, g + gRest * secondFactor, b + bRest * secondFactor)
  }
}
