/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccountBean, AgencyAccountControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters.ts'

class PublisherApi {
  api = new AgencyAccountControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ name, email, status, sort, page }: ListFilters) => {
    function getSort(column: keyof AccountBean) {
      return sort?.name === column ? sort.direction : undefined
    }

    return this.api.getAccountsPageByAgency(
      name !== '' ? name : undefined,
      email !== '' ? email : undefined,
      status?.toString(),
      getSort('id'),
      getSort('status'),
      getSort('lastLoginDate'),
      {
        query: {
          page: page + 1,
          size: LIST_PAGE_SIZE,
        },
      },
    )
  }

  entity = (id: number) => this.api.getAccountByAgency(id)

  create = (bean: AccountBean) => this.api.createAccountByAgency(bean)

  edit = (bean: AccountBean) => this.api.editAccountByAgency(bean, bean.id as number)

  delete = (id: number) => this.api.deleteAccountByAgency(id)

  loginAs = (id: number) => this.api.loginAsPublisherByAgency(id)
}

export const publisherApi = new PublisherApi()
