import { EditOutlined } from '@ant-design/icons'
import { Col, ColorPicker } from 'antd'
import { Color } from 'antd/es/color-picker'
import React, { ComponentProps } from 'react'

import { CustomButton, CustomRow } from 'components/common'

import { GeneratedPalette } from 'pages/settings/sections/generatedPalette/GeneratedPalette'

import styles from 'pages/settings/sections/colorPicker/settingsColorPicker.module.scss'

interface Props {
  value: string
  onChange: (color: Color) => void
  generatedPalette?: ComponentProps<typeof GeneratedPalette>['type']
}

export const SettingsColorPicker: React.FC<Props> = ({ value, onChange, generatedPalette }) => (
  <ColorPicker
    value={value}
    onChange={(color) => onChange(color)}
    disabledAlpha
    panelRender={(panel) => (
      <>
        <CustomRow marginBottom={generatedPalette ? 12 : 0}>
          <Col style={{ flexGrow: 1 }}>{panel}</Col>
        </CustomRow>

        {generatedPalette && <GeneratedPalette type={generatedPalette} reversed />}
      </>
    )}
  >
    <CustomRow className={styles.row}>
      <Col className={styles.squareCol}>
        <div className={styles.square} style={{ background: value }} />
      </Col>

      <Col>
        <CustomButton type="link" icon={<EditOutlined />}>
          Edit color
        </CustomButton>
      </Col>
    </CustomRow>
  </ColorPicker>
)
