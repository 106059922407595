import { FilterFilled, SearchOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import React from 'react'

import styles from 'components/list/filters/filterIcon.module.scss'

interface Props {
  active: boolean
  search?: boolean
}

export const FilterIcon: React.FC<Props> = ({ active, search }) => {
  const className = classNames(styles.icon, { [styles.icon_active]: active })

  return search ? <SearchOutlined className={className} /> : <FilterFilled className={className} />
}
