import { CheckOutlined } from '@ant-design/icons'
import { Col } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { settingsApi } from 'api/settings'

import { colorPaletteManager, defaultColorPalette } from 'utils/colorPalette'
import { createMessage } from 'utils/createMessage'
import { userUpdateStore } from 'utils/userUpdateStore'

import { CustomButton, CustomRow, Explanation, PageTitle, Subtitle } from 'components/common'

import { ChartExamples } from 'pages/settings/examples/ChartExamples'
import { PublisherInterfaceExample } from 'pages/settings/examples/PublisherInterfaceExample'
import { SettingsLogo } from 'pages/settings/logo/SettingsLogo'
import { SettingsPrimarySecondary } from 'pages/settings/sections/SettingsPrimarySecondary'
import { SettingsSystem } from 'pages/settings/sections/SettingsSystem'

export const Settings: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [colorPalette, setColorPalette] = useState(colorPaletteManager.currentPalette)

  useEffect(() => {
    if (containerRef.current) {
      colorPaletteManager.update(colorPalette, containerRef.current)
      document
        .querySelectorAll('.ant-color-picker-inner')
        .forEach((colorPicker) => colorPaletteManager.update(colorPalette, colorPicker as HTMLElement))
    }
  }, [colorPalette])

  function reset() {
    settingsApi.uploadPalette(defaultColorPalette).then(() => {
      createMessage('success', 'Settings are successfullly reset!')
      setColorPalette(defaultColorPalette)
      userUpdateStore.updateInfo()
    })
  }

  function apply() {
    settingsApi.uploadPalette(colorPalette).then(() => {
      createMessage('success', 'Settings are successfullly saved!')
      userUpdateStore.updateInfo()
    })
  }

  return (
    <>
      <CustomRow marginBottom={34}>
        <Col>
          <PageTitle>Settings</PageTitle>
        </Col>
      </CustomRow>

      <SettingsLogo />

      <div ref={containerRef}>
        <CustomRow marginBottom={4}>
          <Col>
            <Subtitle level="h2">Cуstom system colors</Subtitle>
          </Col>
        </CustomRow>

        <CustomRow marginBottom={20}>
          <Col>
            <Explanation size={16}>You can customize your color palette</Explanation>
          </Col>
        </CustomRow>

        <CustomRow marginBottom={80}>
          <Col>
            <SettingsPrimarySecondary colorPalette={colorPalette} setColorPalette={setColorPalette} />
          </Col>

          <Col style={{ marginLeft: 30 }}>
            <ChartExamples />
          </Col>
        </CustomRow>

        <CustomRow marginBottom={34}>
          <Col>
            <SettingsSystem colorPalette={colorPalette} setColorPalette={setColorPalette} />
          </Col>

          <Col style={{ marginLeft: 162 }}>
            <PublisherInterfaceExample />
          </Col>
        </CustomRow>
      </div>

      <CustomRow gutter={8}>
        <Col>
          <CustomButton type="primary" icon={<CheckOutlined />} onClick={apply}>
            Apply
          </CustomButton>
        </Col>

        <Col>
          <CustomButton type="bordered" onClick={reset}>
            Reset settings
          </CustomButton>
        </Col>
      </CustomRow>
    </>
  )
}
