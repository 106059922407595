import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, {Key} from 'react'
import { useHistory } from 'react-router-dom'

import { ManagerBean } from 'api/base/api.ts'
import { userApi } from 'api/user.ts'

import { routes } from 'utils/routes.ts'
import { useGetList } from 'utils/useGetList.ts'
import { getSorter } from 'utils/Util.tsx'

import { CustomButton } from 'components/common/customButton/CustomButton.tsx'
import { CustomRow } from 'components/common/customRow/CustomRow.tsx'
import { PageTitle } from 'components/common/typography/PageTitle.tsx'
import { FilterIcon, getListColumnWidth, ListTable, SelectFilter, TextFilter, useListFilters } from 'components/list'

import ManagerRoleEnum = ManagerBean.ManagerRoleEnum

export const UserList: React.FC = () => {
  const history = useHistory()

  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => userApi.list())

  const { columnWidth } = getListColumnWidth(4, 'noNameColumn')

  function substringFilterFunc(getData: (bean: ManagerBean) => string) {
    return (value: boolean | Key, record: ManagerBean) =>
      getData(record as ManagerBean) != null &&
      getData(record as ManagerBean)
        .toString()
        .toLowerCase()
        .indexOf((value as string).toLowerCase()) !== -1
  }

  function filterFunc(getData: (bean: ManagerBean) => ManagerRoleEnum) {
    return (value: boolean | Key, record: ManagerBean) => getData(record) === value
  }

  const columns: ColumnsType<ManagerBean> = [
    {
      title: 'First Name',
      width: columnWidth,
      ellipsis: true,
      render: (_, { firstName }) => firstName,
      filterIcon: <FilterIcon active={!!filters.firstName} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.firstName} onChange={(value) => setFilter('firstName', value)} filterProps={props} />
      ),
      onFilter: substringFilterFunc((bean: ManagerBean) => bean.firstName),
    },
    {
      title: 'Last Name',
      width: columnWidth,
      ellipsis: true,
      render: (_, { lastName }) => lastName,
      filterIcon: <FilterIcon active={!!filters.lastName} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.lastName} onChange={(value) => setFilter('lastName', value)} filterProps={props} />
      ),
      onFilter: substringFilterFunc((bean: ManagerBean) => bean.lastName),
    },
    {
      title: 'Login',
      width: columnWidth,
      ellipsis: true,
      render: (_, { login }) => login,
      filterIcon: <FilterIcon active={!!filters.login} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.login} onChange={(value) => setFilter('login', value)} filterProps={props} />
      ),
      onFilter: substringFilterFunc((bean: ManagerBean) => bean.login),
    },
    {
      title: 'Role',
      width: columnWidth,
      dataIndex: 'managerRole',
      filterIcon: <FilterIcon active={!!filters.managerRole} />,
      render: (_, { managerRole }) => (managerRole === ManagerRoleEnum.PRIMARY ? 'Primary' : 'Secondary'),
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.managerRole}
          onChange={(value) => setFilter('managerRole', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Primary', value: ManagerRoleEnum.PRIMARY },
              { text: 'Secondary', value: ManagerRoleEnum.SECONDARY },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
      onFilter: filterFunc((bean: ManagerBean) => bean.managerRole),
      sorter: getSorter('managerRole'),
    },
    {
      width: 190,
      ellipsis: true,
      render: (_, { id }) => (
        <CustomButton
          type="shadow"
          size="small"
          icon={<EditOutlined />}
          onClick={() => history.push(routes.user.edit(id))}
        >
          Edit
        </CustomButton>
      ),
    },
  ]

  return (
    <>
      <CustomRow justify="space-between" align="middle" marginBottom={34}>
        <PageTitle>Total users number: {totalEntities}</PageTitle>

        <Col>
          <CustomButton type="primary" linkTo={routes.user.create} icon={<PlusOutlined />}>
            Add User
          </CustomButton>
        </Col>
      </CustomRow>

      <Row>
        <Col>
          <ListTable
            columns={columns}
            list={list}
            isLoading={isLoading}
            currentPage={filters.page}
            totalEntities={totalEntities}
            onPageChange={(page) => setFilter('page', page)}
            onSort={({ order, column }) =>
              setFilter(
                'sort',
                order && column?.dataIndex
                  ? {
                      name: column.dataIndex as string,
                      direction: order === 'ascend' ? 'asc' : 'desc',
                    }
                  : undefined,
              )
            }
          />
        </Col>
      </Row>
    </>
  )
}
