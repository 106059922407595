import { EditOutlined, LoginOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Row, Space } from 'antd'
import { ColumnsType } from 'antd/es/table'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AccountBean } from 'api/base/api'
import { publisherApi } from 'api/publisher'

import { routes } from 'utils/routes'
import { useGetList } from 'utils/useGetList'

import { CustomButton, CustomRow, PageTitle } from 'components/common'
import { FilterIcon, getListColumnWidth, ListTable, SelectFilter, TextFilter, useListFilters } from 'components/list'

import styles from 'pages/publisher/list/publisherList.module.scss'

const { StatusEnum } = AccountBean

export const PublisherList: React.FC = () => {
  const history = useHistory()

  const { filters, setFilter } = useListFilters()
  const [list, { totalEntities, isLoading }] = useGetList(() => publisherApi.list(filters), filters)

  const { nameWidth, columnWidth } = getListColumnWidth(5)

  const columns: ColumnsType<AccountBean> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: 50,
      ellipsis: true,
      render: (_, { id }) => id,
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Name',
      width: nameWidth,
      ellipsis: true,
      render: (_, { firstName }) => firstName,
      filterIcon: <FilterIcon active={!!filters.name} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.name} onChange={(value) => setFilter('name', value)} filterProps={props} />
      ),
    },
    {
      title: 'Email',
      width: nameWidth,
      ellipsis: true,
      render: (_, { email }) => email,
      filterIcon: <FilterIcon active={!!filters.email} />,
      filterDropdown: (props) => (
        <TextFilter value={filters.email} onChange={(value) => setFilter('email', value)} filterProps={props} />
      ),
    },
    {
      title: 'Status',
      width: columnWidth,
      sorter: true,
      dataIndex: 'status',
      render: (_, { status }) => (
        <div
          className={classNames(
            styles.status,
            status === StatusEnum.ACTIVE ? styles.status_active : styles.status_hold,
          )}
        >
          {status}
        </div>
      ),
      filterIcon: <FilterIcon active={!!filters.status} />,
      filterDropdown: (props) => (
        <SelectFilter
          value={filters.status}
          onChange={(value) => setFilter('status', value)}
          getOptions={() =>
            Promise.resolve([
              { text: 'Active', value: StatusEnum.ACTIVE },
              { text: 'Hold', value: StatusEnum.HOLD },
            ])
          }
          filterProps={props}
          allowClear
        />
      ),
    },
    {
      title: 'Last Traffic Date',
      width: columnWidth,
      dataIndex: 'lastTrafficDate',
      render: (_, { lastTrafficDate }) => (lastTrafficDate ? dayjs(lastTrafficDate).format('MMM DD, YYYY') : '-'),
    },
    {
      title: 'Last 30 Days Revenue',
      width: columnWidth,
      dataIndex: 'last30DaysRevenue',
      render: (_, { last30DaysRevenue }) => `${last30DaysRevenue ?? 0} USD`,
    },
    {
      title: '% to previous 30 Days',
      width: columnWidth,
      dataIndex: 'percentToPrevious30Days',
      render: (_, { percentToPrevious30Days }) => (percentToPrevious30Days ? `${percentToPrevious30Days} %` : '-'),
    },
    {
      title: 'Last Login',
      width: columnWidth,
      dataIndex: 'lastLoginDate',
      render: (_, { lastLoginDate }) => (lastLoginDate ? dayjs(lastLoginDate).format('MMM DD, YYYY') : '-'),
      sorter: true,
    },
    {
      title: 'Registration',
      width: columnWidth,
      dataIndex: 'registrationDate',
      render: (_, { registrationDate }) => (registrationDate ? dayjs(registrationDate).format('MMM DD, YYYY') : '-'),
    },
    {
      width: 190,
      ellipsis: true,
      render: (_, { id }) => (
        <Space size={24}>
          <CustomButton
            type="shadow"
            size="small"
            icon={<LoginOutlined />}
            onClick={() =>
              publisherApi.loginAs(id).then((response) => {
                window.open(response.data)
              })
            }
          >
            Login as
          </CustomButton>
          <CustomButton
            type="shadow"
            size="small"
            icon={<EditOutlined />}
            onClick={() => history.push(routes.publisher.edit(id))}
          >
            Edit
          </CustomButton>
        </Space>
      ),
    },
  ]

  return (
    <>
      <CustomRow justify="space-between" align="middle" marginBottom={34}>
        <PageTitle>Total publishers number: {totalEntities}</PageTitle>

        <Col>
          <CustomButton type="primary" linkTo={routes.publisher.create} icon={<PlusOutlined />}>
            Add Publisher
          </CustomButton>
        </Col>
      </CustomRow>

      <Row>
        <Col>
          <ListTable
            columns={columns}
            list={list}
            isLoading={isLoading}
            currentPage={filters.page}
            totalEntities={totalEntities}
            onPageChange={(page) => setFilter('page', page)}
            onSort={({ order, column }) =>
              setFilter(
                'sort',
                order && column?.dataIndex
                  ? {
                      name: column.dataIndex as string,
                      direction: order === 'ascend' ? 'asc' : 'desc',
                    }
                  : undefined,
              )
            }
          />
        </Col>
      </Row>
    </>
  )
}
