import { InfoControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { loginStore } from 'utils/loginStore'

class AccountApi {
  infoApi = new InfoControllerApi(configuration, configuration.basePath, customFetch)

  login = (username: string, password: string): Promise<string> => {
    const form = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`

    const options = {
      method: 'POST',
      body: form,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    return customFetch(`${configuration.basePath}/api/agency/login`, options)
      .then((res: Response) => res.text())
      .then((token: string) => loginStore.login(token))
  }

  logout = () => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    return customFetch(`${configuration.basePath}/api/agency/logout`, options).then(() => loginStore.logout())
  }

  checkUserInfo = () => this.infoApi.managerIsLoggedIn()
}

export const accountApi = new AccountApi()
