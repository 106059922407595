import classNames from 'classnames'
import React, { ComponentProps } from 'react'

import styles from 'components/common/typography/subtitle.module.scss'

interface Props extends ComponentProps<'h1'> {
  level: 'h2' | 'h3'
}

export const Subtitle: React.FC<Props> = ({ level, children, className, ...props }) =>
  level === 'h2' ? (
    <h2 className={classNames(styles.subtitle, styles.subtitle_h2, className)} {...props}>
      {children}
    </h2>
  ) : (
    <h3 className={classNames(styles.subtitle, styles.subtitle_h3, className)} {...props}>
      {children}
    </h3>
  )
