import { Col } from 'antd'
import React from 'react'

import { ColorPalette } from 'utils/colorPalette'

import { CustomRow, Subtitle } from 'components/common'

import { SettingsColorPicker } from 'pages/settings/sections/colorPicker/SettingsColorPicker'

interface Props {
  colorPalette: ColorPalette
  setColorPalette: (value: ColorPalette) => void
}

export const SettingsSystem: React.FC<Props> = ({ colorPalette, setColorPalette }) => (
  <>
    <CustomRow marginBottom={8}>
      <Col>
        <Subtitle level="h3">Sidebar/header color:</Subtitle>
      </Col>
    </CustomRow>

    <CustomRow>
      <Col>
        <SettingsColorPicker
          value={colorPalette['--color-system-1']}
          onChange={(color) => {
            setColorPalette({
              ...colorPalette,
              '--color-system-1': color.toHexString(),
            })
          }}
        />
      </Col>
    </CustomRow>
  </>
)
