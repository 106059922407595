import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import React, { useContext } from 'react'

import { accountApi } from 'api/account'

import { UserContext } from 'utils/userContext'
import { userUpdateStore } from 'utils/userUpdateStore'

import { CustomButton } from 'components/common'

import { logoImage } from 'assets/images'

import styles from 'pages/login/login.module.scss'

export const Login: React.FC = () => {
  const { logoDataURI } = useContext(UserContext)

  const onFinish = (values: { username: string; password: string }) => {
    accountApi.login(values.username, values.password).then(userUpdateStore.updateInfo)
  }

  return (
    <div className={styles.container}>
      <Form className={styles.form} onFinish={onFinish}>
        <div className={styles.logoContainer}>
          <img src={logoDataURI ?? logoImage} className={styles.logo} />
        </div>

        <div className={styles.inputsContainer}>
          <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
            <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <CustomButton type="primary" className={styles.button}>
              Log in
            </CustomButton>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}
