import classNames from 'classnames'
import React, { ComponentProps, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import styles from 'components/common/customButton/customButton.module.scss'

interface Props extends Omit<ComponentProps<'button'>, 'type'> {
  type: 'primary' | 'bordered' | 'shadow' | 'link' | 'disabled' | 'warning'
  size?: 'default' | 'small'
  linkTo?: string
  icon?: ReactNode
  btnType?: 'submit' | 'reset' | 'button' | undefined
}

export const CustomButton: React.FC<Props> = ({ children, className, type, size, linkTo, icon, btnType, ...props }) => (
  <button
    type={btnType || 'submit'}
    className={classNames(
      styles.button,
      {
        [styles.button_small]: size === 'small',
        [styles.button_primary]: type === 'primary',
        [styles.button_warning]: type === 'warning',
        [styles.button_bordered]: type === 'bordered',
        [styles.button_link]: type === 'link',
        [styles.button_disabled]: type === 'disabled',
        [styles.button_shadow]: type === 'shadow',
      },
      className,
    )}
    {...props}
  >
    {linkTo ? (
      <Link to={linkTo} className={styles.inner}>
        {icon && <span className={styles.iconWrapper}>{icon}</span>}
        {children}
      </Link>
    ) : (
      <span className={styles.inner}>
        {icon && <span className={styles.iconWrapper}>{icon}</span>}
        {children}
      </span>
    )}
  </button>
)
