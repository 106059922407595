import { CompareFn } from 'antd/es/table/interface'

import { FilterBean } from 'api/base/api.ts'

export const getSorter =
  (
    name: string,
  ): // eslint-disable-next-line
  CompareFn<any> =>
  (
    a: {
      [key: string]: number | string
    },
    b: {
      [key: string]: number | string
    },
  ) => {
    if (typeof a[name] === 'number') {
      return (a[name] as number) - (b[name] as number)
    }
    return a[name] ? (a[name] as string).localeCompare(b[name] as string) : -1
  }

export const concatObjArrays = (firstArray: FilterBean[], secondArray: FilterBean[]) =>
  Array.from(new Set(firstArray.concat(secondArray))).reduce((acc, item) => {
    if (!acc.find(({ value }) => value === item.value)) {
      acc.push(item)
    }
    return acc
  }, [] as FilterBean[])
