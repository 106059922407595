import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { SorterResult } from 'antd/es/table/interface'
import { useEffect } from 'react'

import styles from 'components/list/layout/listTable.module.scss'

interface Props<Entity> {
  columns: ColumnsType<Entity>
  list: Entity[]
  isLoading: boolean

  currentPage: number
  totalEntities: number | undefined
  onPageChange: (page: number) => void

  onSort?: (sortingResult: SorterResult<Entity>) => void
}

export const LIST_PAGE_SIZE = 10

export const ListTable = <Entity extends { id?: number }>({
  columns,
  list,
  isLoading,
  currentPage,
  totalEntities,
  onPageChange,
  onSort,
}: Props<Entity>) => {
  useEffect(() => {
    if (totalEntities === 0) {
      return
    }
    // if page number exceeds available pages count
    if (currentPage !== 0 && (totalEntities ?? 0) / LIST_PAGE_SIZE <= currentPage) {
      onPageChange(0)
    }
  }, [currentPage, totalEntities, LIST_PAGE_SIZE])

  return (
    <Table
      className={styles.table}
      rowKey="id"
      columns={columns}
      dataSource={list}
      loading={isLoading}
      size="middle"
      tableLayout="fixed"
      pagination={{
        className: styles.pagination,
        current: currentPage + 1,
        onChange: (page) => onPageChange(page - 1),
        pageSize: LIST_PAGE_SIZE,
        position: ['bottomCenter'],
        showSizeChanger: false,
        total: totalEntities,
      }}
      onChange={(_, _1, sortingResult) => onSort?.(sortingResult as SorterResult<Entity>)}
    />
  )
}
