export const routes = {
  statistics: {
    root: '/statistics',
  },
  login: {
    root: '/login',
  },
  logout: {
    root: '/logout',
  },
  publisher: {
    root: '/publisher',
    list: '/publisher/list',
    create: '/publisher/create',
    edit: (id?: number) => `/publisher/edit/${id ?? ':id'}`,
  },
  settings: {
    root: '/settings',
  },
  user: {
    root: '/user',
    list: '/user/list',
    create: '/user/create',
    edit: (id?: number) => `/user/edit/${id ?? ':id'}`,
  },
  customFilter: {
    root: '/custom-filter',
    list: '/custom-filter/list',
    create: '/custom-filter/create',
    edit: (id?: number) => `/custom-filter/edit/${id ?? ':id'}`,
  },
}
