import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { CustomFilterForm } from 'pages/customFilter/form/CustomFilterForm'
import { CustomFilterList } from 'pages/customFilter/list/CustomFilterList'

export const CustomFilterRoot: React.FC = () => (
  <Switch>
    <Route path={routes.customFilter.list} component={CustomFilterList} />
    <Route path={routes.customFilter.create} component={CustomFilterForm} />
    <Route path={routes.customFilter.edit()} component={CustomFilterForm} />
    <Redirect from="*" to={routes.customFilter.list} />
  </Switch>
)
