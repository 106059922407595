import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ManagerBean } from 'api/base/api.ts'
import { userApi } from 'api/user.ts'

import { useFormState } from 'components/form'

export function useUserFormData() {
  const { id } = useParams<{ id: string }>()

  const [formState, setFormState] = useFormState<ManagerBean>()

  useEffect(() => {
    if (id) {
      userApi.entity(+id).then((bean) => {
        setFormState(bean.data)
      })
    } else {
      setFormState({})
    }
  }, [])

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formState: formState ?? {},
    setFormState,
  }
}
