import { CheckOutlined } from '@ant-design/icons'
import { Col, message, Popconfirm } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { ManagerBean } from 'api/base/api.ts'
import { userApi } from 'api/user.ts'

import { routes } from 'utils/routes.ts'

import { CustomRow } from 'components/common'
import { CustomButton } from 'components/common/customButton/CustomButton.tsx'
import { FormContainer, FormSelect, FormTextInput } from 'components/form'

import { useUserFormData } from 'pages/user/form/useUserFormData'

const { ManagerRoleEnum } = ManagerBean

export const UserForm: React.FC = () => {
  const history = useHistory()

  const { isCreation, isFormLoading, formState, setFormState } = useUserFormData()

  const redirectToList = () => {
    history.push(routes.user.list)
  }

  const submit = () => {
    const bean = formState as ManagerBean

    if (isCreation) {
      userApi.create(bean).then(redirectToList)
    } else {
      userApi.edit(bean).then(redirectToList)
    }
  }

  const labelWidth = '200px'

  const handleDelete = (id: number | undefined) => {
    if (id) {
      userApi.delete(id).then(() => {
        message.warning('User is deleted')
        history.push(routes.user.root)
      })
    }
  }

  return (
    <FormContainer title={isCreation ? 'Add New User' : 'Edit User'} isLoading={isFormLoading}>
      <CustomRow>
        <FormTextInput
          span={20}
          label="First Name"
          labelWidth={labelWidth}
          placeholder="First Name"
          value={formState?.firstName}
          onChange={(firstName) => setFormState({ firstName })}
          required
        />
      </CustomRow>

      <CustomRow>
        <FormTextInput
          span={20}
          label="Last Name"
          labelWidth={labelWidth}
          placeholder="Last Name"
          value={formState?.lastName}
          onChange={(lastName) => setFormState({ lastName })}
          required
        />
      </CustomRow>

      <CustomRow marginBottom={10}>
        <FormSelect
          span={8}
          label="Role"
          labelWidth={labelWidth}
          value={formState?.managerRole}
          onChange={(managerRole) => setFormState({ managerRole })}
          options={[
            { label: 'Primary', value: ManagerRoleEnum.PRIMARY },
            { label: 'Secondary', value: ManagerRoleEnum.SECONDARY },
          ]}
          required
        />
      </CustomRow>

      <CustomRow>
        <FormTextInput
          span={20}
          label="Login"
          labelWidth={labelWidth}
          placeholder="Login"
          value={formState?.login}
          onChange={(login) => setFormState({ login })}
          required
        />
      </CustomRow>

      <CustomRow>
        <FormTextInput
          span={20}
          label="Password"
          labelWidth={labelWidth}
          placeholder={isCreation ? 'Password' : "Leave blank if you don't want to change"}
          value={formState?.password}
          onChange={(password) => setFormState({ password })}
          inputProps={{ type: 'password' }}
          required={isCreation}
        />
      </CustomRow>

      <CustomRow>
        <FormTextInput
          span={20}
          label="Confirm password"
          labelWidth={labelWidth}
          placeholder={isCreation ? 'Password' : "Leave blank if you don't want to change"}
          value={formState?.passwordConfirm}
          onChange={(passwordConfirm) => setFormState({ passwordConfirm })}
          inputProps={{ type: 'password' }}
          required={isCreation}
        />
      </CustomRow>

      <CustomRow gutter={10}>
        <Col style={{ marginLeft: labelWidth }}>
          <CustomButton type="primary" icon={<CheckOutlined />} onClick={submit}>
            {isCreation ? 'Add User' : 'Save'}
          </CustomButton>
        </Col>

        <Col span={4}>
          <CustomButton type="bordered" onClick={redirectToList}>
            Cancel
          </CustomButton>
        </Col>

        {!isCreation && (
          <Col>
            <Popconfirm
              title="Are you sure you want to delete User?"
              onConfirm={() => handleDelete(formState.id)}
              okText="Yes"
              cancelText="No"
            >
              <CustomButton type="warning" btnType="button">Delete</CustomButton>
            </Popconfirm>
          </Col>
        )}
      </CustomRow>
    </FormContainer>
  )
}
