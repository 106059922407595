import { BarChartOutlined, FilterOutlined, LogoutOutlined, SettingOutlined, TeamOutlined } from '@ant-design/icons'
import React from 'react'

import { ManagerBean } from 'api/base/api.ts'

import { routes } from 'utils/routes'

import ManagerRoleEnum = ManagerBean.ManagerRoleEnum

interface Item {
  role?: ManagerRoleEnum
  name: string
  to?: string
  image?: React.ReactNode
  spacing?: boolean
}

export const navBarItems: Item[] = [
  { name: 'Publishers', to: routes.publisher.root, image: <TeamOutlined /> },
  { name: 'Statistics', to: routes.statistics.root, image: <BarChartOutlined /> },
  { name: 'Spacing-1', spacing: true },
  { role: ManagerRoleEnum.PRIMARY, name: 'Users', to: routes.user.root, image: <TeamOutlined /> },
  { role: ManagerRoleEnum.PRIMARY, name: 'Settings', to: routes.settings.root, image: <SettingOutlined /> },
  { role: ManagerRoleEnum.PRIMARY, name: 'Custom Filters', to: routes.customFilter.root, image: <FilterOutlined /> },
  { name: 'Logout', to: routes.logout.root, image: <LogoutOutlined /> },
]
