import { Col, Form, Input } from 'antd'
import React from 'react'

import styles from 'components/form/inputs/inputs.module.scss'

interface Props {
  span: string | number
  label?: string
  labelWidth?: string
  placeholder?: string
  value?: string
  onChange: (value: string) => void
  required?: boolean

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  inputProps?: React.ComponentProps<typeof Input>
}

export const FormTextInput: React.FC<Props> = ({
  span,
  label,
  labelWidth,
  placeholder,
  value,
  onChange,
  required,

  colProps,
  itemProps,
  inputProps,
}) => (
  <Col span={span} {...colProps}>
    <Form.Item
      className={styles.formItem}
      label={label}
      labelCol={{ flex: labelWidth }}
      required={required}
      {...itemProps}
      rules={[
        {
          required,
          message: 'Please input DSP name',
        },
      ]}
    >
      <Input
        className={styles.textInput}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        {...inputProps}
        autoComplete="new-password"
      />
    </Form.Item>
  </Col>
)
