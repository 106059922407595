import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AccountBean, ManagerBean } from 'api/base/api'

export interface ListFilters {
  page: number
  sort:
    | {
        name: string
        direction: 'asc' | 'desc'
      }
    | undefined
  name: string
  email: string
  firstName: string
  lastName: string
  login: string
  status: AccountBean.StatusEnum | undefined
  managerRole: ManagerBean.ManagerRoleEnum | undefined
}

const defaultFilters: ListFilters = {
  page: 0,
  sort: {
    name: 'id',
    direction: 'desc',
  },
  name: '',
  email: '',
  firstName: '',
  lastName: '',
  login: '',
  status: undefined,
  managerRole: undefined,
}

const cache: { [key: string]: ListFilters } = {}

export function useListFilters(initialFilters?: Partial<ListFilters>) {
  const location = useLocation()
  const key = location.pathname.split('/').slice(0, 3).join('/')

  const [filters, setFilters] = useState<ListFilters>({ ...defaultFilters, ...cache[key], ...initialFilters })

  function setFilter<T extends keyof ListFilters>(filter: T, value: ListFilters[T]) {
    setFilters((filters) => ({ ...filters, [filter]: value }))
  }

  useEffect(() => {
    cache[key] = filters
  }, [key, filters])

  return { filters, setFilter }
}
