import { AgencyCustomFilterControllerApi, CustomFilterBean } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

import { LIST_PAGE_SIZE } from 'components/list'
import { ListFilters } from 'components/list/utils/useListFilters'

class CustomFilterApi {
  api = new AgencyCustomFilterControllerApi(configuration, configuration.basePath, customFetch)

  list = ({ page }: ListFilters) =>
    this.api.getCustomFiltersPageByAgency({
      query: {
        page: page + 1,
        size: LIST_PAGE_SIZE,
      },
    })

  entity = (id: number) => this.api.getCustomFilterByAgency(id)

  create = (bean: CustomFilterBean) => this.api.createCustomFilterByAgency(bean)

  edit = (bean: CustomFilterBean) => this.api.editCustomFilterByAgency(bean, bean.id as number)

  delete = (id: number) => this.api.deleteCustomFilterByAgency(id)
}

export const customFilterApi = new CustomFilterApi()
