import { Col, Row, Spin } from 'antd'
import React, { createContext, useEffect, useMemo, useState } from 'react'

import { accountApi } from 'api/account'
import { ManagerBean, ManagerInfoBean } from 'api/base/api'

import { ColorPalette, colorPaletteManager } from 'utils/colorPalette'
import { userUpdateStore } from 'utils/userUpdateStore'

import ManagerRoleEnum = ManagerBean.ManagerRoleEnum

interface Values {
  isAuthorized: boolean
  pageUrlEnabled: boolean
  logoDataURI: string | undefined
  userRole: ManagerBean.ManagerRoleEnum | undefined
}

const defaultValues: Values = {
  isAuthorized: false,
  pageUrlEnabled: false,
  logoDataURI: undefined,
  userRole: ManagerRoleEnum.SECONDARY,
}

export const UserContext = createContext(defaultValues)

interface Props {
  children: React.ReactNode
}

export const UserContextProvider: React.FC<Props> = ({ children }) => {
  const [bean, setBean] = useState<ManagerInfoBean>()
  const [isInfoRequested, setIsInfoRequested] = useState(false)

  const refreshUserInfo = () =>
    accountApi
      .checkUserInfo()
      .then((bean) => {
        if (bean.settings) {
          colorPaletteManager.update(bean.settings as ColorPalette)
        } else {
          colorPaletteManager.reset()
        }

        setBean(bean)
      })
      .catch(() => {
        colorPaletteManager.reset()
        setBean(undefined)
      })

  useEffect(() => {
    refreshUserInfo().finally(() => setIsInfoRequested(true))

    const unsubscribe = userUpdateStore.subscribe(refreshUserInfo)

    return unsubscribe
  }, [])

  const value = useMemo<Values>(
    () => ({
      isAuthorized: !!bean?.isLoggedIn,
      pageUrlEnabled: !!bean?.pageUrlEnabled,
      logoDataURI: bean?.logo ? `data:image/png;base64,${bean.logo}` : undefined,
      userRole: bean?.managerRole,
    }),
    [bean],
  )

  return isInfoRequested ? (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  ) : (
    <Row style={{ height: '100vh' }} justify="space-around" align="middle">
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  )
}
