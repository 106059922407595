import { useEffect, useState } from 'react'

import { ListPagination } from 'api/base/api'

import { ListFilters } from 'components/list/utils/useListFilters'

type ListResponse<Entity> = { pagination: ListPagination; data: { data: Entity }[] }

export function useGetList<Entity>(fetchList: () => Promise<ListResponse<Entity>>, filters?: ListFilters) {
  const [list, setList] = useState<Entity[]>([])
  const [totalEntities, setTotalEntities] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const refreshList = () => {
    setIsLoading(true)

    fetchList()
      .then((response) => {
        setList(response.data.map((data) => data.data))
        setTotalEntities(response.pagination.total)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(refreshList, [filters])

  return [list, { refreshList, totalEntities, isLoading }] as const
}
