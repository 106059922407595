import { Col, Form, Switch } from 'antd'
import React from 'react'

import styles from 'components/form/inputs/inputs.module.scss'

interface Props {
  span: string | number
  label?: string
  labelWidth?: string
  checked?: boolean
  onChange: (checked: boolean) => void

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  switchProps?: React.ComponentProps<typeof Switch>
}

export const FormSwitch: React.FC<Props> = ({
  span,
  label,
  labelWidth,
  checked,
  onChange,

  colProps,
  itemProps,
  switchProps,
}) => (
  <Col span={span} {...colProps}>
    <Form.Item className={styles.formItem} label={label} labelCol={{ flex: labelWidth }} {...itemProps}>
      <Switch className={styles.switch} checked={checked} onChange={(e) => onChange(e)} {...switchProps} />
    </Form.Item>
  </Col>
)
