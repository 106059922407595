import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CustomFilterBean } from 'api/base/api'
import { customFilterApi } from 'api/customFilters'

import { useFormState } from 'components/form'

export function useCustomFilterFormData() {
  const { id } = useParams<{ id: string }>()

  const [formName, setFormName] = useState('')
  const [formState, setFormState] = useFormState<CustomFilterBean>()

  useEffect(() => {
    if (id) {
      customFilterApi.entity(+id).then((bean) => {
        setFormName(bean.data.name ?? '')
        setFormState(bean.data)
      })
    } else {
      setFormState({
        dashboardFilter: false,
      })
    }
  }, [])

  return {
    isCreation: !id,
    isFormLoading: !formState,
    formName,
    formState: formState ?? {},
    setFormState,
  }
}
