/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-param-reassign */

/* global RequestInfo, RequestInit */
import isofetch from 'isomorphic-fetch'

import { processGlobalError } from 'api/utils/processGlobalError'

import { loginStore } from 'utils/loginStore'
import { userUpdateStore } from 'utils/userUpdateStore'

export function customFetch(input: RequestInfo, init: RequestInit = {}) {
  init = {
    ...init,
    credentials: 'include',
    headers: {
      ...init.headers,
    },
  }

  if (loginStore.accessToken) {
    init.headers = {
      ...init.headers,
      'X-Access-Token': loginStore.accessToken,
    }
  }

  if (init.body && !(init.headers && (init as any).headers['Content-Type'])) {
    init = {
      ...init,
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  }

  return isofetch(input, init)
    .then((resp: Response) => {
      if (resp.ok) {
        return resp
      }

      if (resp && resp.status === 401) {
        userUpdateStore.updateInfo()
        return Promise.reject(resp)
      }

      return Promise.reject(resp)
    })
    .catch((err: any) => processGlobalError(err))
}
