/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgencyManagerControllerApi, ManagerBean } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class UserApi {
  api = new AgencyManagerControllerApi(configuration, configuration.basePath, customFetch)

  list = () => this.api.getAllManagers()

  entity = (id: number) => this.api.getManager(id)

  create = (bean: ManagerBean) => this.api.createManager(bean)

  edit = (bean: ManagerBean) => this.api.updateManager(bean, bean.id as number)

  delete = (id: number) => this.api.deleteManager(id)
}

export const userApi = new UserApi()
