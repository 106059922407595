import { Col } from 'antd'
import React from 'react'

import { CustomRow } from 'components/common'

import {
  exampleSVG1,
  exampleSVG2,
  exampleSVG3,
  exampleSVG4,
  exampleSVG5,
  exampleSVG6,
  exampleSVG7,
  exampleSVG8,
} from 'pages/settings/examples/chartExampleSVGs'

export const ChartExamples: React.FC = () => (
  <>
    <CustomRow>
      <Col>{exampleSVG1}</Col>
      <Col>{exampleSVG2}</Col>
      <Col>{exampleSVG3}</Col>
      <Col>{exampleSVG4}</Col>
    </CustomRow>

    <CustomRow>
      <Col>{exampleSVG5}</Col>
      <Col>{exampleSVG6}</Col>
      <Col>{exampleSVG7}</Col>
      <Col>{exampleSVG8}</Col>
    </CustomRow>
  </>
)
