import classNames from 'classnames'
import React, { ComponentProps } from 'react'

import styles from 'components/common/typography/pageTitle.module.scss'

export const PageTitle: React.FC<ComponentProps<'h1'>> = ({ children, className, ...props }) => (
  <h1 className={classNames(styles.title, className)} {...props}>
    {children}
  </h1>
)
