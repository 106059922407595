import { CheckOutlined } from '@ant-design/icons'
import { Col } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AccountBean } from 'api/base/api'
import { publisherApi } from 'api/publisher'

import { routes } from 'utils/routes'

import { CustomButton, CustomRow } from 'components/common'
import { FormContainer, FormSelect, FormTextInput } from 'components/form'

import { usePublisherFormData } from 'pages/publisher/form/usePublisherFormData'

import StatusEnum = AccountBean.StatusEnum

const { AccessRightsEnum } = AccountBean

export const PublisherForm: React.FC = () => {
  const history = useHistory()
  const { isCreation, isFormLoading, formState, formName, setFormState } = usePublisherFormData()

  const redirectToList = () => {
    history.push(routes.publisher.list)
  }

  const submit = () => {
    const bean = formState as AccountBean

    if (isCreation) {
      publisherApi.create(bean).then(redirectToList)
    } else {
      publisherApi.edit(bean).then(redirectToList)
    }
  }

  const labelWidth = '140px'

  return (
    <FormContainer title={isCreation ? 'Add New Publisher' : `Edit ${formName}`} isLoading={isFormLoading}>
      <CustomRow>
        <FormTextInput
          span={20}
          label="Name"
          labelWidth={labelWidth}
          placeholder="Publisher name"
          value={formState.firstName}
          onChange={(firstName) => setFormState({ firstName })}
          required
        />
      </CustomRow>

      <CustomRow>
        <FormTextInput
          span={20}
          label="E-mail"
          labelWidth={labelWidth}
          placeholder="Publisher e-mail"
          value={formState.email}
          onChange={(email) => setFormState({ email })}
          required
        />
      </CustomRow>

      <CustomRow>
        <FormTextInput
          span={20}
          label="Password"
          labelWidth={labelWidth}
          placeholder="Password"
          value={formState.password}
          onChange={(password) => setFormState({ password })}
          required
          inputProps={{ type: 'password' }}
        />
      </CustomRow>

      <CustomRow>
        <FormTextInput
          span={20}
          label="Confirm password"
          labelWidth={labelWidth}
          placeholder="Password"
          value={formState.passwordConfirm}
          onChange={(passwordConfirm) => setFormState({ passwordConfirm })}
          required
          inputProps={{ type: 'password' }}
        />
      </CustomRow>

      {formState.agencyPageUrlEnabled && (
        <CustomRow marginBottom={10}>
          <FormSelect
            span={8}
            label="Page URL"
            labelWidth={labelWidth}
            value={formState.pageUrlEnabled}
            onChange={(pageUrlEnabled) => setFormState({ pageUrlEnabled })}
            options={[
              // eslint-disable-next-line
              { label: 'On', value: true as any },
              // eslint-disable-next-line
              { label: 'Off', value: false as any },
            ]}
          />
        </CustomRow>
      )}

      <CustomRow marginBottom={10}>
        <FormSelect
          span={8}
          label="Access rights"
          labelWidth={labelWidth}
          value={formState.accessRights}
          onChange={(accessRights) => setFormState({ accessRights })}
          options={[
            { label: 'Full', value: AccessRightsEnum.FULL },
            { label: 'Read only', value: AccessRightsEnum.READONLY },
          ]}
          required
        />
      </CustomRow>

      <CustomRow marginBottom={10}>
        <FormSelect
          span={8}
          label="Publisher status"
          labelWidth={labelWidth}
          value={formState?.status}
          onChange={(status) => setFormState({ status })}
          options={[
            { label: 'Active', value: StatusEnum.ACTIVE },
            { label: 'Hold', value: StatusEnum.HOLD },
          ]}
          required
        />
      </CustomRow>

      <CustomRow gutter={10}>
        <Col style={{ marginLeft: labelWidth }}>
          <CustomButton type="primary" icon={<CheckOutlined />} onClick={submit}>
            {isCreation ? 'Add Publisher' : 'Save'}
          </CustomButton>
        </Col>

        <Col>
          <CustomButton type="bordered" onClick={redirectToList}>
            Cancel
          </CustomButton>
        </Col>
      </CustomRow>
    </FormContainer>
  )
}
