const width = 173
const height = 144

export const exampleSVG1 = (
  <svg width={width} height={height} viewBox="0 0 166 142" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 28" filter="url(#filter0_d_388_4721)">
      <rect x="11" y="9.70667" width="144" height="120" rx="5.34763" fill="white" />
      <g id="Group 4">
        <path
          id="Ellipse 9"
          d="M131.877 80.5354C132.716 76.6466 133.087 72.6717 132.983 68.695L95.4957 69.6737C95.5217 70.6679 95.4289 71.6617 95.2194 72.6339L131.877 80.5354Z"
          fill="var(--color-secondary-2)"
        />
        <path
          id="Ellipse 10"
          d="M132.89 66.6863C132.804 65.3847 132.666 64.0869 132.478 62.7961L95.3696 68.199C95.4166 68.5217 95.4509 68.8462 95.4725 69.1716L132.89 66.6863Z"
          fill="var(--color-secondary-1)"
        />
        <path
          id="Ellipse 11"
          d="M132.292 61.6146C130.527 51.2431 125.537 41.6923 118.029 34.3218L91.7574 61.0804C93.6341 62.9231 94.8819 65.3108 95.323 67.9037L132.292 61.6146Z"
          fill="var(--color-grey-4)"
        />
        <path
          id="Ellipse 12"
          d="M118.355 105.355C124.175 99.536 128.468 92.3702 130.853 84.4937L94.9633 73.6234C94.3669 75.5925 93.2937 77.384 91.8388 78.8388L118.355 105.355Z"
          fill="var(--color-secondary-3)"
        />
        <path
          id="Ellipse 13"
          d="M116.481 32.8651C110.766 27.7124 103.939 23.9483 96.5317 21.8659C89.124 19.7834 81.3357 19.4387 73.773 20.8587L80.6933 57.7147C82.5839 57.3597 84.531 57.4459 86.3829 57.9665C88.2348 58.4871 89.9416 59.4281 91.3703 60.7163L116.481 32.8651Z"
          fill="var(--color-primary-3)"
        />
        <path
          id="Ellipse 14"
          d="M70.2247 21.6596C60.0803 24.3406 51.0364 30.145 44.3737 38.2508C37.711 46.3567 33.7673 56.3532 33.1009 66.8247L70.5252 69.2062C70.6918 66.5883 71.6778 64.0892 73.3434 62.0627C75.0091 60.0362 77.2701 58.5851 79.8062 57.9149L70.2247 21.6596Z"
          fill="var(--color-primary-2)"
        />
        <path
          id="Ellipse 16"
          d="M33 70C33 79.5966 35.7617 88.9905 40.9554 97.0602C46.1492 105.13 53.5555 111.535 62.2902 115.509C71.0249 119.484 80.719 120.862 90.2152 119.477C99.7114 118.092 108.608 114.003 115.844 107.7L91.211 79.4249C89.4021 81.0009 87.1778 82.023 84.8038 82.3692C82.4298 82.7154 80.0062 82.3711 77.8226 81.3773C75.6389 80.3836 73.7873 78.7825 72.4889 76.7651C71.1904 74.7476 70.5 72.3992 70.5 70L33 70Z"
          fill="var(--color-primary-1)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_388_4721"
        x="0.304748"
        y="0.348319"
        width="165.391"
        height="141.391"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.33691" />
        <feGaussianBlur stdDeviation="5.34763" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0.28 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_388_4721" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_388_4721" result="shape" />
      </filter>
    </defs>
  </svg>
)

export const exampleSVG2 = (
  <svg width={width} height={height} viewBox="0 0 166 142" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_515_3303)">
      <rect x="11" y="9.70679" width="144" height="120" rx="5.3373" fill="white" />
      <g clipPath="url(#clip0_515_3303)">
        <path d="M65.4641 67.9751H32V117H65.4641V67.9751Z" fill="var(--color-primary-1)" />
        <path d="M100.146 41.3311H66.6819V117H100.146V41.3311Z" fill="var(--color-primary-2)" />
        <path d="M134.828 23H101.364V117H134.828V23Z" fill="var(--color-primary-3)" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_515_3303"
        x="0.3254"
        y="0.366512"
        width="165.349"
        height="141.349"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.33432" />
        <feGaussianBlur stdDeviation="5.3373" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0.28 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_515_3303" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_515_3303" result="shape" />
      </filter>
      <clipPath id="clip0_515_3303">
        <rect width="103" height="94" fill="white" transform="translate(32 23)" />
      </clipPath>
    </defs>
  </svg>
)

export const exampleSVG3 = (
  <svg width={width} height={height} viewBox="0 0 172 149" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_386_4498)">
      <g clipPath="url(#clip0_386_4498)">
        <rect x="14" y="12.7068" width="144" height="120" rx="6.86224" fill="white" />
        <path
          d="M126.721 74.0121L157.918 31V132.923H14V31C25.3843 45.3373 51.9129 70.3099 66.9532 55.5015C85.7537 36.991 84.7685 55.0407 98.8073 69.9413C110.038 81.8617 122.096 77.622 126.721 74.0121Z"
          fill="var(--color-primary-3)"
        />
        <path
          d="M126.785 93.134L158 64.0271V133H14V64.0271C25.3908 73.7294 51.9345 90.6287 66.9835 80.6076C85.7946 68.0813 84.8089 80.2958 98.8557 90.3792C110.093 98.446 122.157 95.5769 126.785 93.134Z"
          fill="var(--color-primary-2)"
        />
        <path
          d="M126.785 113.733L158 99.6658V133H14V99.6658C25.3908 104.355 51.9345 112.522 66.9835 107.679C85.7946 101.625 84.8089 107.528 98.8557 112.402C110.093 116.3 122.157 114.914 126.785 113.733Z"
          fill="var(--color-primary-1)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_386_4498"
        x="0.275515"
        y="0.697862"
        width="171.449"
        height="147.449"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.71556" />
        <feGaussianBlur stdDeviation="6.86224" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_386_4498" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_386_4498" result="shape" />
      </filter>
      <clipPath id="clip0_386_4498">
        <rect x="14" y="12.7068" width="144" height="120" rx="6.86224" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const exampleSVG4 = (
  <svg width={width} height={height} viewBox="0 0 172 149" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_386_4503)">
      <g clipPath="url(#clip0_386_4503)">
        <rect x="14" y="12.7068" width="144" height="120" rx="6.86224" fill="white" />
        <path
          d="M14 88.6219L36.0873 72.6052C39.6827 69.998 44.6346 70.3649 47.8065 73.4736V73.4736C50.7377 76.3463 55.2284 76.9032 58.7725 74.8333L80.7067 62.0229C86.3246 58.7419 93.2566 58.6673 98.9438 61.8269V61.8269C103.089 64.1299 107.963 64.7456 112.551 63.5458L138.615 56.73"
          stroke="var(--color-primary-2)"
        />
        <path
          d="M23.1367 102.206L35.2538 64.7789C38.2287 55.5902 48.3547 50.8333 57.3264 54.4099V54.4099C60.0541 55.4974 62.4469 57.2848 64.2634 59.5921L65.2649 60.8642C74.0654 72.0419 90.8546 72.4808 100.227 61.778L106.61 54.4884C111.977 48.3597 119.555 44.6085 127.683 44.0571L158 42"
          stroke="var(--color-secondary-1)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_386_4503"
        x="0.275515"
        y="0.697862"
        width="171.449"
        height="147.449"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.71556" />
        <feGaussianBlur stdDeviation="6.86224" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_386_4503" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_386_4503" result="shape" />
      </filter>
      <clipPath id="clip0_386_4503">
        <rect x="14" y="12.7068" width="144" height="120" rx="6.86224" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const exampleSVG5 = (
  <svg width={width} height={height} viewBox="0 0 166 143" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_515_3228)">
      <rect x="11" y="10.2935" width="144" height="120" rx="5.34763" fill="white" />
      <path
        d="M19 85.4422C23.9231 86.5007 36.7231 85.2834 48.5385 71.946C63.3077 55.2742 56.4154 35.4268 75.1231 24.3122C90.0892 15.4206 106.631 55.5388 113.031 76.7094C117.297 85.5745 130.065 102.273 147 98.1445"
        stroke="var(--color-secondary-1)"
        strokeWidth="2"
      />
      <path d="M30.4595 66.4951H22.4595V121.911H30.4595V66.4951Z" fill="var(--color-primary-1)" />
      <path d="M57.0974 54.1804H49.0974V121.911H57.0974V54.1804Z" fill="var(--color-primary-1)" />
      <path d="M83.7352 18.2625H75.7352V121.911H83.7352V18.2625Z" fill="var(--color-primary-1)" />
      <path d="M110.373 39.8127H102.373V121.911H110.373V39.8127Z" fill="var(--color-primary-1)" />
      <path d="M137.011 86.6775H129.011V121.911H137.011V86.6775Z" fill="var(--color-primary-1)" />
    </g>
    <defs>
      <filter
        id="filter0_d_515_3228"
        x="0.304748"
        y="0.935111"
        width="165.391"
        height="141.391"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.33691" />
        <feGaussianBlur stdDeviation="5.34763" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0.28 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_515_3228" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_515_3228" result="shape" />
      </filter>
    </defs>
  </svg>
)

export const exampleSVG6 = (
  <svg width={width} height={height} viewBox="0 0 172 148" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_515_3236)">
      <rect x="14" y="12.2935" width="144" height="120" rx="6.87552" fill="white" />
      <g clipPath="url(#clip0_515_3236)">
        <path d="M44.617 15H33V23.2979H44.617V15Z" fill="var(--color-primary-1)" />
        <path d="M44.617 23.2979H33V25.1234H44.617V23.2979Z" fill="var(--color-primary-2)" />
        <path d="M44.617 25.1233H33V30.4339H44.617V25.1233Z" fill="var(--color-primary-3)" />
        <path d="M44.617 30.4338H33V37.2381H44.617V30.4338Z" fill="var(--color-secondary-1)" />
        <path d="M44.617 37.2383H33V113.247H44.617V37.2383Z" fill="var(--color-secondary-2)" />
        <path d="M44.617 113.247H33V117.064H44.617V113.247Z" fill="var(--color-secondary-3)" />
        <path d="M44.617 117.064H33V124.532H44.617V117.064Z" fill="var(--color-grey-3)" />
        <path d="M44.617 124.532H33V132H44.617V124.532Z" fill="var(--color-grey-2)" />
        <path d="M60.2341 15H48.6171V23.2979H60.2341V15Z" fill="var(--color-primary-1)" />
        <path d="M60.2341 23.2979H48.6171V25.1234H60.2341V23.2979Z" fill="var(--color-primary-2)" />
        <path d="M60.2341 25.1233H48.6171V30.4339H60.2341V25.1233Z" fill="var(--color-primary-3)" />
        <path d="M60.2341 30.4338H48.6171V37.2381H60.2341V30.4338Z" fill="var(--color-secondary-1)" />
        <path d="M60.2341 37.2383H48.6171V113.247H60.2341V37.2383Z" fill="var(--color-secondary-2)" />
        <path d="M60.2341 113.247H48.6171V117.064H60.2341V113.247Z" fill="var(--color-secondary-3)" />
        <path d="M60.2341 117.064H48.6171V124.532H60.2341V117.064Z" fill="var(--color-grey-3)" />
        <path d="M60.2341 124.532H48.6171V132H60.2341V124.532Z" fill="var(--color-grey-2)" />
        <path d="M75.851 15H64.234V23.2979H75.851V15Z" fill="var(--color-primary-1)" />
        <path d="M75.851 23.2979H64.234V25.1234H75.851V23.2979Z" fill="var(--color-primary-2)" />
        <path d="M75.851 25.1233H64.234V30.4339H75.851V25.1233Z" fill="var(--color-primary-3)" />
        <path d="M75.851 30.4338H64.234V37.2381H75.851V30.4338Z" fill="var(--color-secondary-1)" />
        <path d="M75.851 37.2383H64.234V113.247H75.851V37.2383Z" fill="var(--color-secondary-2)" />
        <path d="M75.851 113.247H64.234V117.064H75.851V113.247Z" fill="var(--color-secondary-3)" />
        <path d="M75.851 117.064H64.234V124.532H75.851V117.064Z" fill="var(--color-grey-3)" />
        <path d="M75.851 124.532H64.234V132H75.851V124.532Z" fill="var(--color-grey-2)" />
        <path d="M91.4681 15H79.8511V23.2979H91.4681V15Z" fill="var(--color-primary-1)" />
        <path d="M91.4681 23.2979H79.8511V25.1234H91.4681V23.2979Z" fill="var(--color-primary-2)" />
        <path d="M91.4681 25.1233H79.8511V30.4339H91.4681V25.1233Z" fill="var(--color-primary-3)" />
        <path d="M91.4681 30.4338H79.8511V37.2381H91.4681V30.4338Z" fill="var(--color-secondary-1)" />
        <path d="M91.4681 37.2383H79.8511V113.247H91.4681V37.2383Z" fill="var(--color-secondary-2)" />
        <path d="M91.4681 113.247H79.8511V117.064H91.4681V113.247Z" fill="var(--color-secondary-3)" />
        <path d="M91.4681 117.064H79.8511V124.532H91.4681V117.064Z" fill="var(--color-grey-3)" />
        <path d="M91.4681 124.532H79.8511V132H91.4681V124.532Z" fill="var(--color-grey-2)" />
        <path d="M107.085 15H95.4681V23.2979H107.085V15Z" fill="var(--color-primary-1)" />
        <path d="M107.085 23.2979H95.4681V25.1234H107.085V23.2979Z" fill="var(--color-primary-2)" />
        <path d="M107.085 25.1233H95.4681V30.4339H107.085V25.1233Z" fill="var(--color-primary-3)" />
        <path d="M107.085 30.4338H95.4681V37.2381H107.085V30.4338Z" fill="var(--color-secondary-1)" />
        <path d="M107.085 37.2383H95.4681V113.247H107.085V37.2383Z" fill="var(--color-secondary-2)" />
        <path d="M107.085 113.247H95.4681V117.064H107.085V113.247Z" fill="var(--color-secondary-3)" />
        <path d="M107.085 117.064H95.4681V124.532H107.085V117.064Z" fill="var(--color-grey-3)" />
        <path d="M107.085 124.532H95.4681V132H107.085V124.532Z" fill="var(--color-grey-2)" />
        <path d="M122.702 15H111.085V23.2979H122.702V15Z" fill="var(--color-primary-1)" />
        <path d="M122.702 23.2979H111.085V25.1234H122.702V23.2979Z" fill="var(--color-primary-2)" />
        <path d="M122.702 25.1233H111.085V30.4339H122.702V25.1233Z" fill="var(--color-primary-3)" />
        <path d="M122.702 30.4338H111.085V37.2381H122.702V30.4338Z" fill="var(--color-secondary-1)" />
        <path d="M122.702 37.2383H111.085V113.247H122.702V37.2383Z" fill="var(--color-secondary-2)" />
        <path d="M122.702 113.247H111.085V117.064H122.702V113.247Z" fill="var(--color-secondary-3)" />
        <path d="M122.702 117.064H111.085V124.532H122.702V117.064Z" fill="var(--color-grey-3)" />
        <path d="M122.702 124.532H111.085V132H122.702V124.532Z" fill="var(--color-grey-2)" />
        <path d="M138.319 15H126.702V23.2979H138.319V15Z" fill="var(--color-primary-1)" />
        <path d="M138.319 23.2979H126.702V25.1234H138.319V23.2979Z" fill="var(--color-primary-2)" />
        <path d="M138.319 25.1233H126.702V30.4339H138.319V25.1233Z" fill="var(--color-primary-3)" />
        <path d="M138.319 30.4338H126.702V37.2381H138.319V30.4338Z" fill="var(--color-secondary-1)" />
        <path d="M138.319 37.2383H126.702V113.247H138.319V37.2383Z" fill="var(--color-secondary-2)" />
        <path d="M138.319 113.247H126.702V117.064H138.319V113.247Z" fill="var(--color-secondary-3)" />
        <path d="M138.319 117.064H126.702V124.532H138.319V117.064Z" fill="var(--color-grey-3)" />
        <path d="M138.319 124.532H126.702V132H138.319V124.532Z" fill="var(--color-grey-2)" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_515_3236"
        x="0.24896"
        y="0.261297"
        width="171.502"
        height="147.502"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.71888" />
        <feGaussianBlur stdDeviation="6.87552" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_515_3236" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_515_3236" result="shape" />
      </filter>
      <clipPath id="clip0_515_3236">
        <rect width="106" height="117" fill="white" transform="translate(33 15)" />
      </clipPath>
    </defs>
  </svg>
)

export const exampleSVG7 = (
  <svg width={width} height={height} viewBox="0 0 166 143" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_386_4455)">
      <g clipPath="url(#clip0_386_4455)">
        <rect x="11" y="10.2935" width="144" height="120" rx="5.34763" fill="white" />
        <path
          d="M20.0844 85.8517L10 74.8251V130.909L155 133L145.307 65.5095L135.027 63.7985L126.117 51.4411L116.425 38.8935L106.536 36.8023L96.4517 33L86.661 63.7985L77.6536 70.2624L68.3525 85.8517L58.8555 73.1141L49.3585 82.6198L39.2741 74.8251L29.5814 80.5285L20.0844 85.8517Z"
          fill="url(#paint0_linear_386_4455)"
          stroke="var(--color-primary-2)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_386_4455"
        x="0.304748"
        y="0.935111"
        width="165.391"
        height="141.391"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.33691" />
        <feGaussianBlur stdDeviation="5.34763" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0.28 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_386_4455" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_386_4455" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_386_4455" x1="82.5" y1="33" x2="82.5" y2="133" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--color-primary-2)" />
        <stop offset="1" stopColor="var(--color-primary-2)" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_386_4455">
        <rect x="11" y="10.2935" width="144" height="120" rx="5.34763" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const exampleSVG8 = (
  <svg width={width} height={height} viewBox="0 0 172 148" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_515_3434)">
      <rect x="14" y="12.2935" width="144" height="120" rx="6.86224" fill="white" />
      <g clipPath="url(#clip0_515_3434)">
        <path d="M56.786 32H14V40.5714H56.786V32Z" fill="var(--color-primary-1)" />
        <path d="M88.023 32H56.7861V40.5714H88.023V32Z" fill="var(--color-primary-2)" />
        <path d="M112.594 32H88.0232V40.5714H112.594V32Z" fill="var(--color-primary-3)" />
        <path d="M130.111 32H112.594V40.5714H130.111V32Z" fill="var(--color-secondary-1)" />
        <path d="M143.056 32H130.111V40.5714H143.056V32Z" fill="var(--color-secondary-2)" />
        <path d="M152.357 32H143.056V40.5714H152.357V32Z" fill="var(--color-secondary-3)" />
        <path d="M156 32H152.357V40.5714H156V32Z" fill="var(--color-grey-3)" />
        <path d="M56.786 46.2856H14V54.8571H56.786V46.2856Z" fill="var(--color-primary-1)" />
        <path d="M88.023 46.2856H56.7861V54.8571H88.023V46.2856Z" fill="var(--color-primary-2)" />
        <path d="M112.594 46.2856H88.0232V54.8571H112.594V46.2856Z" fill="var(--color-primary-3)" />
        <path d="M130.111 46.2856H112.594V54.8571H130.111V46.2856Z" fill="var(--color-secondary-1)" />
        <path d="M143.056 46.2856H130.111V54.8571H143.056V46.2856Z" fill="var(--color-secondary-2)" />
        <path d="M152.357 46.2856H143.056V54.8571H152.357V46.2856Z" fill="var(--color-secondary-3)" />
        <path d="M156 46.2856H152.357V54.8571H156V46.2856Z" fill="var(--color-grey-3)" />
        <path d="M56.786 60.5715H14V69.143H56.786V60.5715Z" fill="var(--color-primary-1)" />
        <path d="M88.023 60.5715H56.7861V69.143H88.023V60.5715Z" fill="var(--color-primary-2)" />
        <path d="M112.594 60.5715H88.0232V69.143H112.594V60.5715Z" fill="var(--color-primary-3)" />
        <path d="M130.111 60.5715H112.594V69.143H130.111V60.5715Z" fill="var(--color-secondary-1)" />
        <path d="M143.056 60.5715H130.111V69.143H143.056V60.5715Z" fill="var(--color-secondary-2)" />
        <path d="M152.357 60.5715H143.056V69.143H152.357V60.5715Z" fill="var(--color-secondary-3)" />
        <path d="M156 60.5715H152.357V69.143H156V60.5715Z" fill="var(--color-grey-3)" />
        <path d="M56.786 74.8572H14V83.4286H56.786V74.8572Z" fill="var(--color-primary-1)" />
        <path d="M88.023 74.8572H56.7861V83.4286H88.023V74.8572Z" fill="var(--color-primary-2)" />
        <path d="M112.594 74.8572H88.0232V83.4286H112.594V74.8572Z" fill="var(--color-primary-3)" />
        <path d="M130.111 74.8572H112.594V83.4286H130.111V74.8572Z" fill="var(--color-secondary-1)" />
        <path d="M143.056 74.8572H130.111V83.4286H143.056V74.8572Z" fill="var(--color-secondary-2)" />
        <path d="M152.357 74.8572H143.056V83.4286H152.357V74.8572Z" fill="var(--color-secondary-3)" />
        <path d="M156 74.8572H152.357V83.4286H156V74.8572Z" fill="var(--color-grey-3)" />
        <path d="M56.786 89.1428H14V97.7143H56.786V89.1428Z" fill="var(--color-primary-1)" />
        <path d="M88.023 89.1428H56.7861V97.7143H88.023V89.1428Z" fill="var(--color-primary-2)" />
        <path d="M112.594 89.1428H88.0232V97.7143H112.594V89.1428Z" fill="var(--color-primary-3)" />
        <path d="M130.111 89.1428H112.594V97.7143H130.111V89.1428Z" fill="var(--color-secondary-1)" />
        <path d="M143.056 89.1428H130.111V97.7143H143.056V89.1428Z" fill="var(--color-secondary-2)" />
        <path d="M152.357 89.1428H143.056V97.7143H152.357V89.1428Z" fill="var(--color-secondary-3)" />
        <path d="M156 89.1428H152.357V97.7143H156V89.1428Z" fill="var(--color-grey-3)" />
        <path d="M56.786 103.428H14V112H56.786V103.428Z" fill="var(--color-primary-1)" />
        <path d="M88.023 103.428H56.7861V112H88.023V103.428Z" fill="var(--color-primary-2)" />
        <path d="M112.594 103.428H88.0232V112H112.594V103.428Z" fill="var(--color-primary-3)" />
        <path d="M130.111 103.428H112.594V112H130.111V103.428Z" fill="var(--color-secondary-1)" />
        <path d="M143.056 103.428H130.111V112H143.056V103.428Z" fill="var(--color-secondary-2)" />
        <path d="M152.357 103.428H143.056V112H152.357V103.428Z" fill="var(--color-secondary-3)" />
        <path d="M156 103.428H152.357V112H156V103.428Z" fill="var(--color-grey-3)" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_515_3434"
        x="0.275515"
        y="0.284532"
        width="171.449"
        height="147.449"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.71556" />
        <feGaussianBlur stdDeviation="6.86224" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_515_3434" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_515_3434" result="shape" />
      </filter>
      <clipPath id="clip0_515_3434">
        <rect width="142" height="80" fill="white" transform="translate(14 32)" />
      </clipPath>
    </defs>
  </svg>
)
