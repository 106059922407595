import classNames from 'classnames'
import React, { ComponentProps } from 'react'

import styles from 'components/common/typography/explanation.module.scss'

interface Props extends ComponentProps<'p'> {
  size: 16 | 14 | 12
}

export const Explanation: React.FC<Props> = ({ size, children, className, style, ...props }) => (
  <p className={classNames(styles.explanation, className)} style={{ fontSize: size, ...style }} {...props}>
    {children}
  </p>
)
