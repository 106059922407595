import { Col, Row, Spin } from 'antd'
import React, { useEffect } from 'react'

import { accountApi } from 'api/account'

import { userUpdateStore } from 'utils/userUpdateStore'

export const Logout: React.FC = () => {
  useEffect(() => {
    accountApi.logout().then(userUpdateStore.updateInfo)
  }, [])

  return (
    <Row style={{ height: '100%' }} justify="space-around" align="middle">
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  )
}
