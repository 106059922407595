import { Row, RowProps } from 'antd'
import React from 'react'

interface Props extends RowProps {
  marginBottom?: number
}

export const CustomRow: React.FC<Props> = ({ children, marginBottom, style, ...props }) => (
  <Row style={{ marginBottom, ...style }} {...props}>
    {children}
  </Row>
)
