import { Col } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { CustomRow, Explanation } from 'components/common'

import styles from 'pages/settings/sections/generatedPalette/generatedPalette.module.scss'

interface Props {
  type: 'primary' | 'secondary'
  reversed?: boolean
}

export const GeneratedPalette: React.FC<Props> = ({ type, reversed }) => (
  <>
    {reversed && (
      <CustomRow marginBottom={2}>
        <Col>
          <Explanation size={12}>Your color palette was generated automatically</Explanation>
        </Col>
      </CustomRow>
    )}

    <CustomRow marginBottom={reversed ? 0 : 2}>
      <Col>
        <div
          className={classNames(
            styles.container,
            type === 'primary' ? styles.container_primary : styles.container_secondary,
          )}
        >
          <div className={styles.item} />
          <div className={styles.item} />
          <div className={styles.item} />
        </div>
      </Col>
    </CustomRow>

    {!reversed && (
      <CustomRow>
        <Col>
          <Explanation size={12}>Your color palette was generated automatically</Explanation>
        </Col>
      </CustomRow>
    )}
  </>
)
