import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { UserForm } from 'pages/user/form/UserForm.tsx'
import { UserList } from 'pages/user/list/UserList.tsx'

export const UserRoot: React.FC = () => (
  <Switch>
    <Route path={routes.user.list} component={UserList} />
    <Route path={routes.user.create} component={UserForm} />
    <Route path={routes.user.edit()} component={UserForm} />
    <Redirect from="*" to={routes.user.list} />
  </Switch>
)
