import { Layout } from 'antd'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { UserContext } from 'utils/userContext'

import { navBarItems } from 'components/appContainer/navBarItems'

import { logoImage } from 'assets/images'

import styles from 'components/appContainer/appContainer.module.scss'

interface Props {
  children: React.ReactNode
}

export const AppContainer: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const { logoDataURI, userRole } = useContext(UserContext)

  return (
    <Layout>
      <Layout.Header className={styles.header}>
        <img src={logoDataURI ?? logoImage} className={styles.logo} />

        <nav className={styles.links}>
          {navBarItems.map(
            (item) =>
              (!item.role || item.role === userRole) &&
              (item.spacing ? (
                <div key={item.name} className={styles.spacing} />
              ) : (
                <Link
                  key={item.name}
                  to={item.to ?? ''}
                  className={classNames(styles.link, {
                    [styles.link_active]: location.pathname.startsWith(item.to as string),
                  })}
                >
                  {item.image}
                  {item.name}
                </Link>
              )),
          )}
        </nav>
      </Layout.Header>

      <Layout.Content className={styles.content}>{children}</Layout.Content>
    </Layout>
  )
}
