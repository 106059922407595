import { Card, Col, Form, Row, Spin } from 'antd'
import React from 'react'

import { CustomRow, PageTitle } from 'components/common'

interface Props {
  title: string | undefined
  isLoading: boolean
  children?: React.ReactNode
}

export const FormContainer: React.FC<Props> = ({ title, isLoading, children }) =>
  isLoading ? (
    <Card title={title}>
      <Row justify="space-around">
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    </Card>
  ) : (
    <>
      <CustomRow marginBottom={44}>
        <Col>
          <PageTitle>{title}</PageTitle>
        </Col>
      </CustomRow>
      <Form requiredMark>{children}</Form>
    </>
  )
