import { SearchOutlined } from '@ant-design/icons'
import { Input, InputRef, Space } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import React, { useEffect, useRef, useState } from 'react'

import { CustomButton } from 'components/common'

import styles from 'components/list/filters/textFilter.module.scss'

interface Props {
  value: string
  onChange: (value: string) => void
  filterProps: FilterDropdownProps
}

export const TextFilter: React.FC<Props> = ({ value, onChange, filterProps }) => {
  const [tempValue, setTempValue] = useState(value)

  const filter = () => {
    onChange(tempValue)
    filterProps.setSelectedKeys(tempValue ? [tempValue] : [])
    filterProps.confirm()
    filterProps.close()
  }

  const reset = () => {
    onChange('')
    setTempValue('')
    filterProps.setSelectedKeys([])
    filterProps.confirm()
    filterProps.close()
  }
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus())
  }, [filterProps])

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={inputRef}
        className={styles.input}
        placeholder="Search"
        value={tempValue}
        onChange={(e) => setTempValue(e.target.value)}
        onPressEnter={filter}
        style={{ marginBottom: 8, display: 'block' }}
      />

      <Space>
        <CustomButton type="primary" size="small" icon={<SearchOutlined />} onClick={filter} className={styles.button}>
          Search
        </CustomButton>

        <CustomButton type="bordered" size="small" onClick={reset} className={styles.button}>
          Reset
        </CustomButton>
      </Space>
    </div>
  )
}
