import { ReportQuery } from 'api/base/api'
import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { colorPaletteManager } from 'utils/colorPalette'
import { loginStore } from 'utils/loginStore'
import { routes } from 'utils/routes'
import { UserContext } from 'utils/userContext'
import { appConfiguration } from 'api/utils/appConfiguration'
import { AppContainer } from 'components/appContainer'
import { CustomFilterRoot } from 'pages/customFilter'
import { Login } from 'pages/login'
import { Logout } from 'pages/logout'
import { PublisherRoot } from 'pages/publisher'
import * as ProductionCharts from '@adend/asteriobid-ui-lib'
import * as TestingCharts from '@adend/asteriobid-ui-lib-testing'
import { Settings } from 'pages/settings'
import { UserRoot } from 'pages/user'

import 'src/utils/commonstyles.scss'
import MetricsEnum = ReportQuery.MetricsEnum
import KeysEnum = ReportQuery.KeysEnum

if (appConfiguration.library === 'production') {
    import('@adend/asteriobid-ui-lib/dist/index.css')
}

if (appConfiguration.library === 'testing') {
    import('@adend/asteriobid-ui-lib-testing/dist/index.css')
}

colorPaletteManager.reset()

export const App: React.FC = () => {
    const {isAuthorized} = useContext(UserContext)

    return isAuthorized ? (
        <AppContainer>
            <Switch>
                <Route path={routes.logout.root} component={Logout}/>
                <Route path={routes.publisher.root} component={PublisherRoot}/>
                <Route
                    path={routes.statistics.root}
                    render={() =>
                        appConfiguration.library === 'production' ? (
                            <ProductionCharts.AuctionsReportScreen
                                token={`${loginStore.accessToken}`}
                                apiUrl={import.meta.env.VITE_APPLICATION_ENDPOINT}
                                profileCurrency="USD"
                                initialKeys={[KeysEnum.AccountEmail.toString()]}
                                initialMetrics={[MetricsEnum.AdUnitRequests.toString(), MetricsEnum.Impressions.toString()]}
                            />
                        ) : (
                            <TestingCharts.AuctionsReportScreen
                                token={`${loginStore.accessToken}`}
                                apiUrl={import.meta.env.VITE_APPLICATION_ENDPOINT}
                                profileCurrency="USD"
                                initialKeys={[KeysEnum.AccountEmail.toString()]}
                                initialMetrics={[MetricsEnum.AdUnitRequests.toString(), MetricsEnum.Impressions.toString()]}
                            />
                        )
                    }
                />
                <Route path={routes.user.root} component={UserRoot}/>
                <Route path={routes.settings.root} component={Settings}/>
                <Route path={routes.customFilter.root} component={CustomFilterRoot}/>
                <Redirect from="*" to={routes.publisher.root}/>
            </Switch>
        </AppContainer>
    ) : (
        <Switch>
            <Route path={routes.login.root} component={Login}/>
            <Redirect from="*" to={routes.login.root}/>
        </Switch>
    )
}
