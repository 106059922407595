import { CheckOutlined } from '@ant-design/icons'
import { Col, message, Popconfirm, Tabs } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { CustomFilterBean, CustomFilterValueBean } from 'api/base/api'
import { customFilterApi } from 'api/customFilters'

import { routes } from 'utils/routes'

import { CustomButton, CustomRow } from 'components/common'
import { FormContainer, FormEntityList, FormInputNumber, FormSwitch, FormTextInput } from 'components/form'

import { useCustomFilterFormData } from 'pages/customFilter/form/useCustomFilterFormData'

export const CustomFilterForm: React.FC = () => {
  const history = useHistory()
  const { isCreation, isFormLoading, formState, formName, setFormState } = useCustomFilterFormData()

  const redirectToList = () => {
    history.push(routes.customFilter.list)
  }

  const submit = () => {
    const bean = formState as CustomFilterBean

    if (isCreation) {
      customFilterApi.create(bean).then(redirectToList)
    } else {
      customFilterApi.edit(bean).then(redirectToList)
    }
  }

  const labelWidth = '140px'

  const mainTab = () => (
    <>
      <CustomRow>
        <FormTextInput
          span={24}
          label="Name"
          labelWidth={labelWidth}
          value={formState.name}
          onChange={(name) => setFormState({ name })}
          required
        />
      </CustomRow>

      <CustomRow>
        <FormInputNumber
          span={10}
          label="Field ID (range: 1-5)"
          labelWidth={labelWidth}
          value={formState.fieldId}
          min={1}
          max={5}
          onChange={(fieldId) => setFormState({ fieldId })}
          required
        />
      </CustomRow>

      <CustomRow>
        <FormTextInput
          span={24}
          label="Adapter config field"
          labelWidth={labelWidth}
          value={formState.adapterConfigField}
          onChange={(adapterConfigField) => setFormState({ adapterConfigField })}
          required
        />
      </CustomRow>

      <CustomRow>
        <FormSwitch
          span={10}
          label="Dashboard filter"
          labelWidth={labelWidth}
          checked={formState.dashboardFilter}
          onChange={(dashboardFilter) => setFormState({ dashboardFilter })}
        />
      </CustomRow>
    </>
  )

  const valuesTab = () => (
    <FormEntityList
      label={
        <>
          <Col span={8}>Label</Col>
          <Col span={8}>Value</Col>
        </>
      }
      list={formState.customFilterValues ?? []}
      updateList={(customFilterValues) => setFormState({ customFilterValues })}
      getNewEntity={() => ({}) as CustomFilterValueBean}
      renderRow={(bean, index) => (
        <>
          <FormTextInput
            span={8}
            value={bean.label}
            onChange={(label) => {
              const customFilterValues = [...(formState.customFilterValues ?? [])]
              customFilterValues[index] = { ...customFilterValues[index], label }
              setFormState({ customFilterValues })
            }}
            required
          />

          <FormTextInput
            span={8}
            value={bean.value}
            onChange={(value) => {
              const customFilterValues = [...(formState.customFilterValues ?? [])]
              customFilterValues[index] = { ...customFilterValues[index], value }
              setFormState({ customFilterValues })
            }}
            required
          />
        </>
      )}
      minimumOneRow
    />
  )

  const handleDelete = (id: number | undefined) => {
    if (id) {
      customFilterApi.delete(id).then(() => {
        message.warning('Custom filter is deleted')
        history.push(routes.customFilter.root)
      })
    }
  }

  return (
    <FormContainer title={isCreation ? ' Add New Custom Filter' : `Edit ${formName}`} isLoading={isFormLoading}>
      <Tabs
        items={[
          {
            key: 'main',
            label: 'Main',
            children: mainTab(),
          },
          {
            key: 'values',
            label: 'Values',
            children: valuesTab(),
          },
        ]}
      />

      <CustomRow gutter={10}>
        <Col style={{ marginLeft: labelWidth }}>
          <CustomButton type="primary" icon={<CheckOutlined />} onClick={submit}>
            {isCreation ? 'Add Custom Filter' : 'Save'}
          </CustomButton>
        </Col>

        <Col>
          <CustomButton type="bordered" onClick={redirectToList}>
            Cancel
          </CustomButton>
        </Col>

        {!isCreation && (
          <Col>
            <Popconfirm
              title="Are you sure you want to delete this custom filter?"
              onConfirm={() => handleDelete(formState.id)}
              okText="Yes"
              cancelText="No"
            >
              <CustomButton type="warning" btnType="button">Delete</CustomButton>
            </Popconfirm>
          </Col>
        )}
      </CustomRow>
    </FormContainer>
  )
}
