import { Col } from 'antd'
import React from 'react'

import { ColorPalette } from 'utils/colorPalette'

import { CustomRow, Explanation, Subtitle } from 'components/common'

import { SettingsColorPicker } from 'pages/settings/sections/colorPicker/SettingsColorPicker'
import { createColorShades } from 'pages/settings/sections/createColorShades'
import { GeneratedPalette } from 'pages/settings/sections/generatedPalette/GeneratedPalette'

interface Props {
  colorPalette: ColorPalette
  setColorPalette: (value: ColorPalette) => void
}

export const SettingsPrimarySecondary: React.FC<Props> = ({ colorPalette, setColorPalette }) => (
  <>
    <CustomRow marginBottom={2}>
      <Col>
        <Subtitle level="h3">Primary color:</Subtitle>
      </Col>
    </CustomRow>

    <CustomRow marginBottom={8}>
      <Col>
        <Explanation size={14}>Used for charts and buttons</Explanation>
      </Col>
    </CustomRow>

    <CustomRow marginBottom={12}>
      <Col>
        <SettingsColorPicker
          value={colorPalette['--color-primary-1']}
          onChange={(color) => {
            const shades = createColorShades(color)

            setColorPalette({
              ...colorPalette,
              '--color-primary-1': shades.color,
              '--color-primary-2': shades.firstShade,
              '--color-primary-3': shades.secondShade,
            })
          }}
          generatedPalette="primary"
        />
      </Col>
    </CustomRow>

    <CustomRow marginBottom={32}>
      <Col>
        <GeneratedPalette type="primary" />
      </Col>
    </CustomRow>

    <CustomRow marginBottom={2}>
      <Col>
        <Subtitle level="h3">Secondary color (charts, active menu tab):</Subtitle>
      </Col>
    </CustomRow>

    <CustomRow marginBottom={8}>
      <Col>
        <Explanation size={14}>Used for charts and butotns</Explanation>
      </Col>
    </CustomRow>

    <CustomRow marginBottom={12}>
      <Col>
        <SettingsColorPicker
          value={colorPalette['--color-secondary-1']}
          onChange={(color) => {
            const shades = createColorShades(color)

            setColorPalette({
              ...colorPalette,
              '--color-secondary-1': shades.color,
              '--color-secondary-2': shades.firstShade,
              '--color-secondary-3': shades.secondShade,
            })
          }}
          generatedPalette="secondary"
        />
      </Col>
    </CustomRow>

    <CustomRow>
      <Col>
        <GeneratedPalette type="secondary" />
      </Col>
    </CustomRow>
  </>
)
