import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'utils/routes'

import { PublisherForm } from 'pages/publisher/form/PublisherForm'
import { PublisherList } from 'pages/publisher/list/PublisherList'

export const PublisherRoot: React.FC = () => (
  <Switch>
    <Route path={routes.publisher.list} component={PublisherList} />
    <Route path={routes.publisher.create} component={PublisherForm} />
    <Route path={routes.publisher.edit()} component={PublisherForm} />
    <Redirect from="*" to={routes.publisher.list} />
  </Switch>
)
