import { Col, Form, InputNumber } from 'antd'
import React from 'react'

import styles from 'components/form/inputs/inputs.module.scss'

interface Props {
  span: string | number
  label?: string
  labelWidth?: string
  placeholder?: string
  value?: number
  min?: number
  max?: number
  onChange: (value: number) => void
  required?: boolean

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  inputProps?: React.ComponentProps<typeof InputNumber>
}

export const FormInputNumber: React.FC<Props> = ({
  span,
  label,
  labelWidth,
  placeholder,
  value,
  min,
  max,
  onChange,
  required,

  colProps,
  itemProps,
  inputProps,
}) => (
  <Col span={span} {...colProps}>
    <Form.Item
      className={styles.formItem}
      label={label}
      labelCol={{ flex: labelWidth }}
      required={required}
      {...itemProps}
      rules={[
        {
          required,
          message: 'This field is required',
        },
      ]}
    >
      <InputNumber
        className={styles.textInput}
        value={value}
        min={min}
        max={max}
        onChange={(e) => e && onChange(e as number)}
        placeholder={placeholder}
        {...inputProps}
      />
    </Form.Item>
  </Col>
)
